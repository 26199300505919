<script>
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'

export default {
  name: 'drawer-confirmation-bottomsheet',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      default: 'error',
      validator: (v) => /success|warning|error/.test(v),
    },
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 60,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { isDarkThemeForEwallet } = useCms()

    const isConfirming = ref(false)

    function hide() {
      emit('hide')
    }

    async function onConfirmClicked() {
      if (props.onConfirm) {
        isConfirming.value = true

        await props.onConfirm()

        isConfirming.value = false
      }

      hide()
    }

    async function onCancelClicked() {
      if (props.onCancel) {
        await props.onCancel()
      }

      hide()
    }

    return {
      isDarkThemeForEwallet,
      isConfirming,
      hide,
      onConfirmClicked,
      onCancelClicked,
    }
  },
}
</script>

<template>
  <div
    class="px-5 pt-5"
    :class="{
      'bg-gray-900 text-white': isDarkThemeForEwallet,
    }"
  >
    <div
      class="mb-[30px] flex h-72 items-center justify-center bg-center bg-no-repeat pt-2.5"
      :style="{
        backgroundImage: `url('/images/drawer/confirmation-modal/header-bg-${type}.svg')`,
      }"
    >
      <div
        class="flex h-[120px] w-[120px] items-center justify-center rounded-full text-white"
        :class="{
          'bg-orange-700': type === 'warning',
          'bg-red-700': type === 'error',
          'bg-emerald-700': type === 'success',
        }"
      >
        <slot name="icon">
          <base-icon :svg="icon" :size="iconSize" />
        </slot>
      </div>
    </div>
    <slot name="title">
      <div
        class="mb-4 text-center text-2xl font-bold leading-8"
        :class="{
          'text-eonx-neutral-800': !isDarkThemeForEwallet,
          'text-white': isDarkThemeForEwallet,
        }"
      >
        {{ title }}
      </div>
    </slot>
    <slot name="text">
      <div
        class="mb-4 text-center text-base font-normal leading-6"
        :class="{
          'text-eonx-neutral-600': !isDarkThemeForEwallet,
          'text-white': isDarkThemeForEwallet,
        }"
      >
        {{ text }}
      </div>
    </slot>
    <div class="mt-[30px] pb-5">
      <base-button
        :full-width="true"
        :loading="isConfirming"
        :disabled="isConfirming"
        @click="onConfirmClicked"
      >
        {{ confirmButtonText }}
      </base-button>
      <base-button
        v-if="showCancelButton"
        color="none"
        :disabled="isConfirming"
        class="w-full py-4 font-bold text-primary"
        @click="onCancelClicked"
      >
        {{ cancelButtonText }}
      </base-button>
    </div>
  </div>
</template>
